import React from 'react';
import HeaderMenu from '../RootComponent/HeaderMenu/HeaderMenu';
import Slidder from './Slidder';
import FoodCart from './FoodCart/FoodCart';
import BanerAfterComponent from './BanerAfterComponent/BanerAfterComponent';
import HomeBigFooter from './HomeBigFooter/HomeBigFooter';
import Footer from '../RootComponent/Footer/Footer';
import VideoSection from './VideoSection/VideoSection';

const Home = () => {
    return (
        <div >
            <HeaderMenu />
            <Slidder />
            <FoodCart />
            <VideoSection />
            <HomeBigFooter />
            <BanerAfterComponent />
            <Footer />
        </div>
    );
};

export default Home;
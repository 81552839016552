import React from 'react';

const ContactMap = () => {
    const iframeStyle = {
        border: '8px solid white',
        borderRedious: '8px'
    };

    return (
        <div className='col-span-12 rounded-md'>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.839481928721!2d90.36321498538604!3d22.697018675307305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755340e82fc17b3%3A0x4359b36325a84784!2sHOT%20PLATE%20Restaurant!5e0!3m2!1sen!2sbd!4v1698725891666!5m2!1sen!2sbd" 
                width="100%" 
                height="450" 
                style={iframeStyle} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    );
};

export default ContactMap;
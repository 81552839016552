import React from 'react';
import '../../Home/BanerAfterComponent/BanerAfterComponent.css';
import FooterAddress from './FooterAddress';
import FooterNavigation from './FooterNavigation';
import FooterSocialLink from './FooterSocialLink';
const Footer = () => {
    return (
        <div className='md:grid grid-cols-12'>
            <div className='col-span-12 bg-[#1F2025]'>
                <div className='md:grid grid-cols-12 rounded-lg bg-white mt-4 mx-auto w-11/12 p-4'>
                 <FooterAddress />
                 <FooterNavigation />
                 <FooterSocialLink />
                </div>
            </div>
            <div className='col-span-12 bg-[#1F2025]'>
                    <h3 className='text-center text-[#FD6A04] font-link'>@Hotpale - 2023</h3>
            </div>
        </div>
    );
};

export default Footer;
import React from 'react';
import { motion } from 'framer-motion';
import home from '../RootComponent/Footer/Icon/home.png';
import email from '../RootComponent/Footer/Icon/email.png';
import phone from '../RootComponent/Footer/Icon/phone.png';

const ContactMessage = () => {
    return (
        <motion.div
        initial={{ x: 300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        className='lg:col-span-4 sm:col-span-12 sm:my-4 lg:my-0 lg:mx-0 font-link'>
              <div className='font-medium px-4 py-2 sm:my-12'>
                <div className='flex my-1'>
                    <img src={phone}  className='h-6 w-6'/>
                    <h3 className='mx-2 text-lmd text-[#FD6A04] font-link'>+88 01717 306399</h3>
                </div>
                <div className='flex my-1'>
                    <img src={email}  className='h-6 w-6'/>
                    <h3 className='mx-2 text-md text-[#FD6A04] font-link'>info@hotplatebd.com</h3>
                </div>
                <div className='flex my-1'>
                    <img src={home}  className='h-6 w-6'/>
                    <h3 className='mx-2 text-md text-[#FD6A04] font-link'>416 Police Line Rd, Barisal, Bangladesh</h3>
                </div>
            </div>
        </motion.div>
    );
};

export default ContactMessage;
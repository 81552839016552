import React, { useState } from 'react';
import logoHotPlate from './Icon/logoHotPlate.png';
import cross from './Icon/cross.png';
import toggle from './Icon/toggle.png';
import { Link } from 'react-router-dom';
const HeaderMenu = () => {
  const routes = [
    {
      id: 1,
      name: "Home",
      path: "/"
    },
    {
      id: 2,
      name: "About",
      path: "/about"
    },
    {
      id: 3,
      name: "Menu",
      path: "/menu"
    },
    {
      id: 4,
      name: "Chefs",
      path: "/chefs"
    },
    {
      id: 5,
      name: "Contact",
      path: "/contact"
    }
  ];

  const [open, setOpen] = useState(false);

  return (
    <div className='grid grid-cols-12 bg-[#1F2025] shadow-sm w-full sticky top-0 z-50 border-b-[#FC5706]'>
      {/* Logo section */}
      <div className='col-span-3'>
        <img src={logoHotPlate} className='w-24 h-24 mx-12 my-2 relative' />
      </div>

      {/* Menu section (conditionally rendered) */}
      <div className='col-span-9 text-white text-xl my-10'>
        <div onClick={() => setOpen(!open)} className='md:hidden float-right mx-4'>
          {
            open === true ? <img  src={cross} className='h-8 w-8'/>: <img  src={toggle} className='h-8 w-8'/>
          }
        </div>

        <ul className={`md:static md:flex ml-4 absolute duration-500 z-50 bg-[#1F2025] w-1/2 ${open ? 'top-6' : '-top-72'}`}>
          {
            routes.map(route => (
              <li key={route.id} className='sm:mx-10 text-[#FC5706] hover:text-white px-3 py-1 '><Link to={route.path}>{route.name}</Link></li>
            ))
          }
        </ul>
      </div>

    </div>
  );
};

export default HeaderMenu;

import React from 'react';
import { motion } from 'framer-motion';
import ContactForm from './ContactForm';
import ContactMessage from './ContactMessage';
import ContactMap from './ContactMap';

const ContactBody = () => {
    return (
      <div className='sm:grid grid-cols-12 gap-2 bg-[#FD6A04]'>
          <div className='sm:col-span-12'>
        <div className='sm:grid grid-cols-12'>
            <div className='col-span-12 pb-28'>
                    <div className='sm:w-8/12 mx-auto bg-white mt-32'>
                    <motion.div
                    initial={{ y: -300, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }} 
                    className='text-center text-5xl py-16 font-link -skew-y-12 text-[#FD6A04]'>
                        Contact us
                    </motion.div>
                    <div className='sm:grid grid-cols-12 gap-2 pb-16'>
                        <ContactForm />
                        <ContactMessage />
                    </div>
                    <div className='sm:grid grid-cols-12 gap-2'>
                        <ContactMap />
                    </div>
                    </div>
            </div>
        </div>
        </div>
      </div>
    );
};

export default ContactBody;
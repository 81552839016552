import React from 'react';
import imageBanAf from './iconPeople.png';
import './BanerAfterComponent.css';
import { Link } from 'react-router-dom';

const BanerAfterComponent = () => {
  return (
    <div className='sm:grid sm:grid-cols-12'>
      <div className='sm:col-span-12 w-11/12 mx-auto my-8'>
        <div className='sm:grid sm:grid-cols-12 bg-white shadow-md shadow-gray-300 rounded-lg border-2 border-gray-100 p-1'>
          <div className='sm:col-span-12 lg:col-span-10 px-8 py-8'>
            <div className='flex'>
              <Link to='https://www.facebook.com/groups/1062886087190521/'>
                <img src={imageBanAf} className='' />
              </Link>

              <div className='sm:my-4 sm:mx-4'>
                <h3 className='text-2xl text-[#FD6A04] font-bold'>আমাদের অফিসিয়াল ফেসবুক গ্রূপ</h3>
                <p className='text-lg font-medium'>Join the 5400+ people</p>
              </div>
            </div>
          </div>
          <div className='sm:col-span-12 lg:col-span-2'>
            <Link to='https://www.facebook.com/groups/1062886087190521/'>
              <div className='border-2 border-[#1F2025] rounded-lg sm:my-16 sm:mx-4'>
                <h3 className='px-1 py-2 text-2xl bg-[#1F2025] text-white text-center'>জয়েন</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BanerAfterComponent;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import MenuItem from './Component/MenuItem/MenuItem';
import Chefs from './Component/Chefs/Chefs';
import Contact from './Component/Contact/Contact';
function App() {
  const router = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/menu', element: <MenuItem /> },
    { path: '/chefs', element: <Chefs /> },
    { path: '/contact', element: <Contact /> },
  ])
  return (
    <div className="App">
     <RouterProvider router={router} />
    </div>
  );
}

export default App;

import React from 'react';
import HeaderMenu from '../RootComponent/HeaderMenu/HeaderMenu';
import ChefsDashboard from './ChefsDashboard';
import Footer from '../RootComponent/Footer/Footer';

const Chefs = () => {
    return (
        <div>
            <HeaderMenu />
            <ChefsDashboard />
            <Footer />
        </div>
    );
};

export default Chefs;
import React from 'react';
import { motion } from 'framer-motion';
const OurTalk = () => {
    return (
        <div className='sm:grid sm:grid-cols-12 gap-2'>
            
                <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className='sm:col-span-12 w-11/12 mx-auto sm:my-24'
            >
                <motion.div
                initial={{ y: -300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className='text-center sm:text-5xl text-[#FD6506]'>
                
                আমাদের কথা
                </motion.div>
                    <p className='mx-2 sm:mt-12 text-[#69290C] lg:text-lg sm:text-md'>
                    বরিশাল শহরের প্রাণকেন্দ্রে প্রিমিয়ার বরিশাল খাবার রেস্টুরেন্টে স্বাগতম। আমরা আমাদের খাঁটি এবং ঐতিহ্যবাহী খাবারের জন্য গর্ব করি, যা আমাদের অভিজ্ঞ শেফরা দক্ষতার সাথে তৈরি করে। আমাদের মেনুতে রয়েছে মুখের জল আনা বিরিয়ানি, রসালো কাবাব এবং তাজা উপাদান দিয়ে তৈরি অন্যান্য ক্লাসিক বাংলাদেশি খাবারের একটি বিস্তৃত নির্বাচন।
                    </p>
                    <p className='mx-2 mt-4 text-[#69290C] lg:text-lg sm:text-md'>
                    হট প্লেটে, আমরা আমাদের অতিথিদের একটি উষ্ণ এবং আমন্ত্রণমূলক পরিবেশ প্রদান করি যা বাংলাদেশের সাংস্কৃতিক ঐতিহ্যকে মূর্ত করে।
                    </p>

                    <p className='mx-2 mt-4 text-[#69290C] lg:text-lg sm:text-md'>
                    তাই আসুন এবং হট প্লেটে বরিশালের স্বাদ আবিষ্কার করুন। আমরা শীঘ্রই আপনাকে পরিবেশন করার জন্য উন্মুখ!
                    </p>
                </motion.div>
        </div>
    );
};

export default OurTalk;
import React from 'react';
import { motion } from 'framer-motion';
const ContactForm = () => {
    return (
        <motion.div
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        className='lg:col-span-8 mx-8 sm:col-span-12 font-link lg:border-r-2 lg:border-[#FD6A04]'>
            <form>
                <div className='my-2'>
                    <label>Name</label>
                    <div>
                        <input 
                            type='text'
                            className='border w-11/12 px-2 rounded-md py-1'
                            placeholder='Enter your name'
                        />

                    </div>
                </div>

                <div className='my-2'>
                    <label>Phone</label>
                    <div>
                        <input 
                            type='text'
                            className='border w-11/12 px-2 rounded-md py-1'
                            placeholder='01XXXXXXXXX'
                        />

                    </div>
                </div>

                <div className='my-2'>
                    <label>Email</label>
                    <div>
                        <input 
                            type='text'
                            className='border w-11/12 px-2 rounded-md py-1'
                            placeholder='Email address'
                        />

                    </div>
                </div>

                <div className='my-2'>
                    <label>Subject</label>
                    <div>
                        <input 
                            type='text'
                            className='border w-11/12 px-2 rounded-md py-1'
                            placeholder='Enter subject'
                        />

                    </div>
                </div>

                <div className='my-2'>
                    <label>Message</label>
                    <div>
                        <textarea
                            type='text'
                            className='border w-11/12 px-2 rounded-md py-1'
                            placeholder='Email subject'
                        ></textarea>

                    </div>
                </div>
                <div>
                    <button className='border px-2 py-1 bg-[#FD6A03] hover:bg-[#000000] text-white rounded-sm'>Submit</button>
                </div>
            </form>
        </motion.div>
    );
};

export default ContactForm;
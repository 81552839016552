import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import loveIcon from './loveIcon.png';

const FoodCart = () => {
    const [cartImage, setCartImage] = useState([]);

    useEffect(() => {
        fetch('cartImages/cart.json')
            .then(res => res.json())
            .then(data => setCartImage(data.images))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleImageHover = (index) => {
        const updatedImages = cartImage.map((image, i) =>
            i === index ? { ...image, isHovered: true } : { ...image, isHovered: false }
        );
        setCartImage(updatedImages);
    };

    return (
        <div className='md:grid grid-cols-12 gap-4 mx-16 my-12'>
            <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className='sm:col-span-12 text-3xl font-bold text-center my-4 text-[#FC6B06]'>
                আমাদের স্পেশাল মেনু
            </motion.div>
            {cartImage.map((img, index) => (
                <div
                    key={img.id}
                    className='relative lg:col-span-3 sm:col-span-6 mx-2'
                    onMouseEnter={() => handleImageHover(index)}
                    onMouseLeave={() => handleImageHover(-1)}
                // style={{ position: 'relative' }}

                >
                    <div>
                        <img
                            src={`cartImages/${img.url}`}
                            alt={img.title}
                            className='hover:shadow-md hover:shadow-[#FC6B06] lg:w-full sm:w-3/4 sm:my-4 lg:my-0 sm:mx-auto lg:mx-0 rounded-lg'
                        />
                    </div>
                    {img.isHovered && (
                        // <div style={{ position: 'absolute', bottom: 6, left: 6 }}>
                        <div className='absolute lg:bottom-8 lg:left-6'>
                            {/* <img src={loveIcon} alt='Like' className='h-6 w-6' /> */}
                        </div>
                    )}

                    <div>
                        <h3 className='text-center mt-4 text-[#FC6B06] border-b-4 border-[#FC6B06] font-bold py-2 text-xl'>{img.title} | {img.item_for}</h3>
                        <p className='text-[#FC6B06] text-center font-bold'>{img.price}</p>
                        <p className='text-center text-[#FC6B06]'>
                            {/* {img.details} */}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FoodCart;

import React from 'react';
import { motion } from 'framer-motion';
import image1 from '../OurStory/Images/1.png';
import image2 from '../OurStory/Images/2.png';
import image3 from '../OurStory/Images/3.png';
import image4 from '../OurStory/Images/4.png';
const StoryFoodCart = () => {
    return (
        <div className='sm:grid sm:grid-cols-12 gap-4'>
        <div className='sm:col-span-6 gap-2 my-4'>
            <div className='sm:grid-cols-12 gap-2'>
                <motion.div
                initial={{ x: -300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                
                className='sm:col-span-12 max-w-sm'>
                        <img src={image1} className='rounded-lg shadow-md shadow-[#95796E] p-1'/>
                </motion.div>

                <motion.div
                initial={{ y: 300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                 className='sm:col-span-12 max-w-sm mt-4'>
                      <img src={image2} className='rounded-lg shadow-md shadow-[#95796E] p-1'/>
                </motion.div>
            </div>
        </div>
        {/* Another grid */}
        <div className='sm:col-span-6 gap-2 my-16'>
            <div className='sm:grid-cols-12 gap-2'>
                <motion.div
                initial={{ y: -300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className='sm:col-span-12 max-w-sm'>
                    <img src={image3} className='rounded-lg shadow-md shadow-[#95796E] p-1'/>
                </motion.div>

                <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                 className='sm:col-span-12 max-w-sm mt-4'>
                    <img src={image4} className='rounded-lg shadow-md shadow-[#95796E] p-1'/>
                </motion.div>
            </div>
        </div>
    </div>
    );
};

export default StoryFoodCart;
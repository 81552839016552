import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import HeaderMenu from '../RootComponent/HeaderMenu/HeaderMenu';
import MenuItemList from './MenuItemList';
import Footer from '../RootComponent/Footer/Footer';
import RiceMenuList from './RiceMenuList';
import Chowmeinnoodles from './Chowmeinnoodles';
import Chinesecuisine from './Chinesecuisine';
import Soup from './Soup';
import Fish from './Fish';
import Naanruti from './Naanruti';
import Drinks from './Drinks';

const MenuItem = () => {
    const [showAll, setShowAll] = useState(true);
    const [activeSection, setActiveSection] = useState(null);

    const toggleAll = () => {
        setShowAll(!showAll);
        setActiveSection(null);
    };

    const toggleSection = (section) => {
        setActiveSection(section);
        setShowAll(false);
    };

    useEffect(() => {
        // Initially, show all components
        setShowAll(true);
    }, []);

    return (
        <div>
            <HeaderMenu />
            <div className='my-4 sm:grid sm:grid-cols-12 justify-center justify-items-center'>
                <div className='sm:col-span-12'>
                    <motion.div
                        initial={{ x: -300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.8 }}
                        className='text-3xl my-3 font-bold text-[#FC6B06]'>
                        সমস্ত দিনের হট প্লেটের আয়োজন
                    </motion.div>
                </div>
                <div className='sm:col-span-12 mt-8 mb-2'>
                    <motion.div
                        initial={{ x: 300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.8 }}
                        className=''>
                        <button
                            onClick={toggleAll}
                            className={`border ${
                                showAll ? 'bg-black text-white' : 'border-gray-300 hover:bg-black hover:text-white'
                            } bg-[#FC6B06] rounded-tl-full rounded-br-full px-4 py-1`}>
                            All
                        </button>
                        <button
                            onClick={() => toggleSection('MenuItemList')}
                            className={`border ${
                                activeSection === 'MenuItemList'
                                    ? 'bg-black text-white'
                                    : 'border-[#FC6B06] hover:bg-black hover:text-white'
                            } rounded-tl-full rounded-br-full px-4 py-1`}>
                            Bangla
                        </button>
                        <button
                            onClick={() => toggleSection('RiceMenuList')}
                            className={`border ${
                                activeSection === 'RiceMenuList'
                                    ? 'bg-black text-white'
                                    : 'border-[#FC6B06] hover:bg-black hover:text-white'
                            } rounded-tl-full rounded-br-full px-4 py-1`}>
                            Rice
                        </button>
                        <button 
                        onClick={() => toggleSection('Chowmeinnoodles')} 
                        className={`border ${
                            activeSection === 'Chowmeinnoodles'
                                ? 'bg-black text-white'
                                : 'border-[#FC6B06] hover:bg-black hover:text-white'
                        } rounded-tl-full rounded-br-full px-4 py-1`}>
                            Noodles</button>

                        <button 
                        onClick={() => toggleSection('Chinesecuisine')} 
                        className={`border ${
                            activeSection === 'Chinesecuisine'
                                ? 'bg-black text-white'
                                : 'border-[#FC6B06] hover:bg-black hover:text-white'
                        } rounded-tl-full rounded-br-full px-4 py-1`}>
                            Chinese</button>
                        
                        <button 
                        onClick={() => toggleSection('Soup')} 
                        className={`border ${
                            activeSection === 'Soup'
                                ? 'bg-black text-white'
                                : 'border-[#FC6B06] hover:bg-black hover:text-white'
                        } rounded-tl-full rounded-br-full px-4 py-1`}>
                        Soup</button>
                        <button 
                        onClick={() => toggleSection('Fish')} 
                        className={`border ${
                            activeSection === 'Fish'
                                ? 'bg-black text-white'
                                : 'border-[#FC6B06] hover:bg-black hover:text-white'
                        } rounded-tl-full rounded-br-full px-4 py-1`}>
                            Fish</button>

                        <button 
                        onClick={() => toggleSection('Naanruti')} 
                        className={`border ${
                            activeSection === 'Naanruti'
                                ? 'bg-black text-white'
                                : 'border-[#FC6B06] hover:bg-black hover:text-white'
                        } rounded-tl-full rounded-br-full px-4 py-1`}>
                            Bread</button>
                        
                        <button 
                        onClick={() => toggleSection('Drinks')} 
                        className={`border ${
                            activeSection === 'Drinks'
                                ? 'bg-black text-white'
                                : 'border-[#FC6B06] hover:bg-black hover:text-white'
                        } rounded-tl-full rounded-br-full px-4 py-1`}>
                            Drinks</button>
                        {/* Repeat the same pattern for other buttons */}
                    </motion.div>
                </div>
            </div>

            {showAll && (
                <div>
                    <MenuItemList />
                    <RiceMenuList />
                    <Chowmeinnoodles />
                    <Chinesecuisine />
                    <Soup />
                    <Fish />
                    <Naanruti />
                    <Drinks />
                </div>
            )}

            {activeSection === 'MenuItemList' && <MenuItemList />}
            {activeSection === 'RiceMenuList' && <RiceMenuList />}
            {activeSection === 'Chowmeinnoodles' && <Chowmeinnoodles />}
            {activeSection === 'Chinesecuisine' && <Chinesecuisine />}
            {activeSection === 'Soup' && <Soup />}
            {activeSection === 'Fish' && <Fish />}
            {activeSection === 'Naanruti' && <Naanruti />}
            {activeSection === 'Drinks' && <Drinks />}
            <Footer />
        </div>
    );
};

export default MenuItem;

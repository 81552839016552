import React from 'react';
import HeaderMenu from '../RootComponent/HeaderMenu/HeaderMenu';
import Footer from '../RootComponent/Footer/Footer';
import OurStory from './OurStory/OurStory';

const About = () => {
    return (
        <div>
           <HeaderMenu />
           <OurStory />
           <Footer />
        </div>
    );
};

export default About;
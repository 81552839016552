import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slider1 from '../images/banner1.png';
import slider2 from '../images/banner2.png';
import slider3 from '../images/banner3.png';
import slider4 from '../images/banner4.png';

const Slidder = () => {
    const images = [slider1, slider2,slider3,slider4];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, // Slide every 2 seconds
    };


    return (
        <div className='grid grid-cols-1'>
            <div className='col-span-1 w-11/12 mt-8 mx-auto rounded-md'>
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <img key={index} src={image} alt={`Banner ${index + 1}`} className='h-auto rounded-md' />
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Slidder;

import React from 'react';
import { Link } from 'react-router-dom';
import '../../Home/BanerAfterComponent/BanerAfterComponent.css';

const FooterNavigation = () => {
    return (
        <div className='lg:col-span-5 md:col-span-6'>
            <nav className='flex justify-center text-lg font-link text-[#FD6A04]'>
                <div>
                <h3 className=' text-black'>Useful Links</h3>
                    <ul className='my-1'><li><Link to='/about'>Our Story</Link></li></ul>
                    <ul className='my-1'><li><Link to='/menu'>Menu Items</Link></li></ul>
                    <ul className='my-1'><li><Link to='/chefs'>Our Skill Chefs</Link></li></ul>
                    <ul className='my-1'><li><Link to='/contact'>Contact</Link></li></ul>
                </div>
            </nav>
        </div>
    );
};

export default FooterNavigation;
import React from 'react';
import logo from '../HeaderMenu/Icon/logoHotPlate.png';
import '../../Home/BanerAfterComponent/BanerAfterComponent.css';
import facebook from '../HeaderMenu/Icon/facebook.png';
import instragram from '../HeaderMenu/Icon/instrgram.png';
import twitter from '../HeaderMenu/Icon/twitter.png';
import { Link } from 'react-router-dom';
const FooterAddress = () => {
    return (
        <div className='lg:col-span-3 md:col-span-6'>
        <div className='flex justify-center'>
            <img src={logo} className='h-24 w-24 mt-4' />
        </div>
        <h3 className='text-xl font-link text-center mt-4 text-black'>We're open everyday</h3>
        <div className='flex justify-center'>
            <div className='font-medium'>
                <nav className=''>
                    <ul className='flex mx-2'>
                        <li className='mx-2'>
                            <Link to='https://www.facebook.com/groups/1062886087190521/'>
                                 <img src={facebook} className='h-8 w-8' />
                                 </Link>
                        </li>
                        {/* <li className='mx-2'>
                            <img src={instragram} className='h-8 w-8' />
                        </li>
                        <li className='mx-2'>
                            <img src={twitter} className='h-8 w-8' />
                        </li> */}
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    );
};

export default FooterAddress;
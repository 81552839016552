import React from 'react';
import { motion } from 'framer-motion';
import '../Home/BanerAfterComponent/BanerAfterComponent.css';
import male from './Icon/male.png';
import female from './Icon/femle.png';
const ChefsDashboard = () => {
    return (
        <div className='my-8'>
            <motion.div
                    initial={{ y: -300, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                className='text-5xl font-semibold text-center py-16 font-link text-[#FD6A04]'>
                Our Chefs
            </motion.div>
            <div className='sm:grid grid-cols-12 gap-4 mx-16'>
                <motion.div
                    initial={{ x: -300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}

                    className='lg:col-span-3 sm:col-span-12 mx-8'>
                    <div className='w-full bg-[#D8866E] rounded-lg'>
                        <img src={male} className='' />
                    </div>
                    <div>
                        <h3 className='text-center mt-4 font-link text-2xl text-[#FD6A04] border-b-4 border-[#FD6A04] py-2 mx-20'>Abdul Zalil</h3>
                        <p className='text-[#FD6A04] text-center font-bold text-lg mt-2'>দীর্ঘ ৩০ বছরের অভিজ্ঞতাসম্পন্ন রাধূনী</p>
                    </div>
                </motion.div>

                <motion.div
                    initial={{ x: 300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                    className='lg:col-span-3 sm:col-span-12 mx-8'>
                    <div className='w-full bg-[#D8866E] rounded-lg'>
                        <img src={female} className='' />
                    </div>
                    <div>
                        <h3 className='text-center mt-4 font-link text-2xl text-[#FD6A04] border-b-4 border-[#FD6A04] py-2 mx-20'>Raima Rahman</h3>
                        <p className='text-[#FD6A04] text-center font-bold text-lg mt-2'>দীর্ঘ ১০ বছরের অভিজ্ঞতাসম্পন্ন রাধূনী</p>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default ChefsDashboard;
import React from 'react';
import ReactPlayer from 'react-player';
import video from './video.mp4';

const VideoSection = () => {
    return (
        <div className='sm:grid sm:grid-cols-12 w-11/12 mx-auto'>
            <div className='sm:col-span-12 lg:col-span-12 w-full border border-[#FC6B06] opacity-70 p-1 rounded-md'>
                <ReactPlayer
                    url={video}
                    width="100%"
                    height=""
                    controls={false}
                    playing={true}
                    muted={true}
                    loop={true}
                    playsinline={true}
                />
            </div>
        </div>
    );
};

export default VideoSection;
import React, { useEffect, useState } from 'react';
import './BanerAfterComponent.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const HomeBigFooter = () => {
    const [image, setImage] = useState([]);

    useEffect(() => {
        fetch('sliderBig/sliderBig.json')
            .then(res => res.json())
            .then(data => setImage(data.images))
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    const settings = {
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2, 
        slidesToScroll: 2,
    };

    return (
        <div className='sm:grid sm:grid-cols-12 mx-10'>
            <div className='lg:col-span-6 sm:col-span-12'>
                <h3 className='text-center sm:my-44 text-3xl font-bold text-[#FC6B06]'>আমাদের ডেলিভারি পার্টনার</h3>
            </div>
            <div className='lg:col-span-6 sm:col-span-12 shadow-gray-300 shadow-lg sm:my-8 rounded-lg border-gray-100 w-11/12 mx-auto border'>
                <div className='sm:grid sm:grid-cols-12 gap-2'>
                    
                        <Slider {...settings} className='sm:col-span-12'>
                        {image.map((img, index) => (
                            <img
                                key={index}
                                src={`sliderBig/${img.url}`}
                                className='' 
                                />
                        ))}
                        </Slider>
                    
                </div>
            </div>
        </div>
    );
};

export default HomeBigFooter;

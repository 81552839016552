import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Soup = () => {
    const [cartImage, setCartImage] = useState([]);

    useEffect(() => {
        fetch('hotPlateItem/soup/soup.json')
            .then(res => res.json())
            .then(data => setCartImage(data.images))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const settings = {
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <motion.div
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.6}}
        className='my-8'>
        <h3 className='text-2xl font-extrabold font-link mx-20 py-8 text-[#FC6B06]'>Soup</h3>
        <Slider {...settings} className='sm:grid grid-cols-12 gap-4 mx-16'>
            
            {cartImage.map((img, index) => (
                // <div key={img.id} className='lg:col-span-3 sm:col-span-12 mx-2'>
                <div key={img.id} className='px-2'>
                    <div>
                        <img
                            src={`hotPlateItem/soup/${img.url}`}
                            alt={img.title}
                            className='p-1 w-full rounded-lg'
                        />
                    </div>
                    <div>
                        <h3 className='text-center mt-4 text-xl text-[#FC6B06] border-b-4 border-[#FC6B06] py-2'>{img.title} | {img.item_for}</h3>
                        <p className='text-[#FC6B06] text-center font-bold text-lg'>{img.price}</p>
                        <p className='text-xs text-center text-[#FC6B06]'>
                            {/* {img.details} */}
                        </p>
                    </div>
                </div>
            ))}
        </Slider>
    </motion.div>
    );
};

export default Soup;
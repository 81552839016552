import React from 'react';
import StoryFoodCart from './StoryFoodCart';
import OurTalk from './OurTalk';

const OurStory = () => {
    return (
        <div className='sm:grid sm:grid-cols-12 gap-2 w-10/12 mx-auto my-8'>
            <div className='sm:col-span-12 lg:col-span-6'>
              <StoryFoodCart />
            </div>

            <div className='sm:col-span-12 lg:col-span-6'>
                <OurTalk />
            </div>
        </div>
    );
};

export default OurStory;
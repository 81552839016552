import React from 'react';
import HeaderMenu from '../RootComponent/HeaderMenu/HeaderMenu';
import ContactBody from './ContactBody';
import Footer from '../RootComponent/Footer/Footer';

const Contact = () => {
    return (
        <div>
            <HeaderMenu />
            <ContactBody />
            <Footer />
        </div>
    );
};

export default Contact;
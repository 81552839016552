import React from 'react';
import home from './Icon/home.png';
import email from './Icon/email.png';
import phone from './Icon/phone.png';
const FooterSocialLink = () => {
    return (
        <div className='lg:col-span-4 sm:col-span-12 pb-4'>
            <div className='flex justify-center'>
            <div className='font-medium sm:mt-8'>
                <div className='flex my-1'>
                    <img src={phone}  className='h-6 w-6'/>
                    <h3 className='mx-2 text-lmd text-[#FD6A04] font-link'>+88 01717 306399</h3>
                </div>
                <div className='flex my-1'>
                    <img src={email}  className='h-6 w-6'/>
                    <h3 className='mx-2 text-md text-[#FD6A04] font-link'>info@hotplatebd.com</h3>
                </div>
                <div className='flex my-1'>
                    <img src={home}  className='h-6 w-6'/>
                    <h3 className='mx-2 text-md text-[#FD6A04] font-link'>416 Police Line Rd, Barisal, Bangladesh</h3>
                </div>
            </div>
            </div>
        </div>
    );
};

export default FooterSocialLink;